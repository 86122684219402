import React, { useContext } from 'react';
import { BgImage } from 'gbimage-bridge';
import { Link } from 'gatsby';

import {
	Button,
	Typography,
	Grid,
	makeStyles,
	useMediaQuery,
} from '@material-ui/core';

import FormModalContext from '../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
  subheader: {
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
  },
	buttonContainer: {
		height: '100%',
		justifyContent: 'center',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'flex-start',
      marginTop: '2rem',
		},
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
      marginTop: 0,
		},
    [theme.breakpoints.down('xs')]: {
			marginBottom: '2rem',
		},
	},
	button: {
		padding: '16px 32px',
		opacity: 1,
		fontSize: '1.1rem',
		fontWeight: 500,
		// zIndex: 1000,
		transition: 'all 0.35s ease !important',
		'&:hover': {
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '1rem',
		},
	},
}));

export const UpperCtaCard = ({
	background,
	header,
	subheader,
	ctaText,
	ctaLink,
	internalLink,
}) => {
	const classes = useStyles();

	const { handleModalClick } = useContext(FormModalContext);

	const md = useMediaQuery('(max-width: 960px)');
	const sm = useMediaQuery('(max-width: 600px)');
	return (
		<div style={{ padding: sm ? '4rem 0 0' : '4rem 0', position: 'relative' }}>
			<Grid
				container
				direction='row'
				justifyContent='space-evenly'
				alignItems='center'
				style={{ height: '100%' }}>
				<Grid
					item
					container
					direction='column'
					xs={12}
					lg={7}
					justifyContent='space-evenly'
					alignItems={md ? 'center' : 'flex-start'}
					style={{ height: '100%' }}>
					<Typography
						variant='h2'
						color='primary'
						style={{ marginBottom: '1rem' }}>
						{header}
					</Typography>
					<Typography variant='body1' className={classes.subheader}>{subheader}</Typography>
				</Grid>
				<Grid
					item
					container
					alignItems='center'
					className={classes.buttonContainer}
					xs={12}
					lg={3}>
					{ctaLink ? (
						internalLink ? (
							<Link to={`/${ctaLink}`} style={{ textDecoration: 'none' }}>
								{' '}
								<Button
									variant='contained'
									size='large'
									color='secondary'
									className={classes.button}>
									{ctaText}
								</Button>
							</Link>
						) : (
							<a
								href={ctaLink}
								target='_blank' aria-label="Opens in a new tab"
								style={{ textDecoration: 'none' }}>
								<Button
									variant='contained'
									size='large'
									color='secondary'
									className={classes.button}>
									{ctaText}
								</Button>
							</a>
						)
					) : (
						<Button
							variant='contained'
							size='large'
							color='secondary'
							onClick={(e) => handleModalClick()}
							className={classes.button}>
							{ctaText}
						</Button>
					)}
				</Grid>
			</Grid>
		</div>
	);
};
