import React from 'react';
import PortableText from 'react-portable-text';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: '2rem',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '2rem 0',
		},
	},
	introHeader: {
		lineHeight: 1.0,
		[theme.breakpoints.down('xs')]: {
			lineHeight: 1.2,
		},
	},
	paragraph: {
		lineHeight: '32px',
		marginTop: '1rem',
	},
}));

export const Intro = ({ intro }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<PortableText
				content={intro}
				serializers={{
					h2: ({ children }) => (
						<Typography
							variant='h2'
							color='primary'
							className={classes.introHeader}>
							{children}
						</Typography>
					),
					normal: ({ children }) => (
						<Typography variant='body1' className={classes.paragraph}>
							{children}
						</Typography>
					),
				}}
			/>
		</div>
	);
};
