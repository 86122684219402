import React, { useContext } from 'react';
import { BgImage } from 'gbimage-bridge';

import {
  Button,
  Typography,
  Grid,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';

import FormModalContext from '../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
  bgImg: {
    padding: '1.5rem 0',
    [theme.breakpoints.down('lg')]: {
      padding: '2rem 0',
    },
    [theme.breakpoints.down('md')]: {
      padding: '1.5rem .625rem',
    },
  },
  button: {
    background: 'white',
    color: '#002D5C',
    padding: '16px 32px',
    opacity: 1,
    fontSize: '1.1rem',
    fontWeight: 500,
    zIndex: 1000,
    transition: 'all 0.35s ease !important',
    '&:hover': {
      opacity: '0.85 !important',
    },
    [theme.breakpoints.down('md')]: {
      margin: '2rem 0 .5rem',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1rem 0',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '2rem 0',
    },
  },
  buttonContainer: {
    height: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  calloutHeader: {
    fontSize: '2.5rem',
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
      textAlign: 'left',
      padding: '15px 0 0 15px',
    },
  },
  calloutSubHeader: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
      padding: '0 15px',
    },
  },
}));

export const LowerCtaCard = ({
  background,
  header,
  subheader,
  ctaText,
  sunset,
}) => {
  const classes = useStyles();

  const { handleModalClick } = useContext(FormModalContext);

  const md = useMediaQuery('(max-width: 960px)');
  const sm = useMediaQuery('(max-width: 600px)');
  return (
    <div style={{ padding: '4rem 0' }}>
      <BgImage
        style={{
          borderRadius: '20px',
          overflow: 'hidden',
          // backgroundSize: 'cover',
        }}
        className={classes.bgImg}
        image={background.asset?.gatsbyImageData}
      >
        <Grid
          container
          direction='row'
          justifyContent='space-evenly'
          alignItems='center'
          style={{ height: '100%' }}
        >
          <Grid
            item
            container
            direction='column'
            xs={12}
            md={10}
            lg={7}
            justifyContent='space-evenly'
            alignItems='flex-start'
            style={{ height: '100%' }}
          >
            <Typography
              variant='h2'
              style={{
                color: '#FFF',
                marginBottom: '1rem',
                fontSize: sunset ? '3rem' : null,
              }}
              className={classes.calloutHeader}
            >
              {header}
            </Typography>
            <Typography
              variant='body1'
              style={{ color: '#FFF' }}
              className={classes.calloutSubHeader}
            >
              {subheader}
            </Typography>
          </Grid>
          <Grid
            item
            container
            alignItems='center'
            xs={12}
            lg={3}
            className={classes.buttonContainer}
          >
            <Button
              variant='contained'
              size='large'
              onClick={(e) => handleModalClick()}
              className={classes.button}
            >
              {ctaText}
            </Button>
          </Grid>
        </Grid>
      </BgImage>
    </div>
  );
};
