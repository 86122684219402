import React from 'react';
import { BgImage } from 'gbimage-bridge';

import { makeStyles } from '@material-ui/core';

import { WaveUpSVG } from '../../WaveSVGs/WaveUpSVG';
import { ReverseWaveDownSVG } from '../../WaveSVGs/ReverseWaveDownSVG';

const useStyles = makeStyles((theme) => ({
	topWave: {
    marginTop: '-8rem',
		marginBottom: '-8rem',
		[theme.breakpoints.down('md')]: {
      marginTop: '-4rem',
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
      marginTop: '-2rem',
			marginBottom: '-2rem',
		},
	},
	imgCont: {
		padding: '16rem 0',
		height: '200px',
		zIndex: -1,
		[theme.breakpoints.down('md')]: {
			padding: '10rem  0',
		},
	},
}));

export const CenterWave = ({ background }) => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.topWave}>
				<ReverseWaveDownSVG fill='#FFF' />
			</div>
			<BgImage
				className={classes.imgCont}
				image={background.asset?.gatsbyImageData}></BgImage>
			<WaveUpSVG fill='#FFF' />
		</>
	);
};
